.levels {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    height: 50px;
    width: 80%;
    margin-top: 2rem;
}

.main-level {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    background-color: black;
    /* border: 1px solid blue; */
    border-radius: 50%;

}

.main-level span {
    color: white;
    font: 1rem;
}

.line-level {
    height: 5px;
    border-radius: 5px;
    width: 30%;
    background-color: black;
}

.activation,
.activation span {
    
    color: white;
    background-color: rgba(111,159,190,1);
}