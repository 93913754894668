.question-survey {
    margin: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px;
    flex-direction: column;
    height: 625px;
    width: 90%;
    background-color: white;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
}

.text-start {
    text-align: start !important;
}

.head-question {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 13%;
    border-radius: 4px;
    /* border-bottom: 1px solid #c5c3c3; */
}

.headque-span {
    height: 80%;
    width: 85%;
    text-align: justify;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 8px;
}

.divque-span {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-start;
    width: 87%;
    line-height: 2rem;
}

.divque-input {
    height: 35%;
    width: 7%;
    margin-left: 15px;
}

.body-question {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 97%;
    height: 68%;
}

.bodyque-div {
    width: 95%;
    height: 23%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    /* padding-right: 2rem; */
}

.btn-question {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* flex-direction: column; */
    width: 80%;
    height: 13%;
    padding: 0rem 1rem;
}

.btnque-button {
    height: 40px;
    width: 190px;
    border: 0px;
    outline: 0px;
    background: rgba(111,159,190,1);
    border-radius: 4px;
    font-size: 22px;
    color: white;
}

.btnque-button:hover {
    background-color: rgb(79 124 154);
}

.btnque-button:active {
    background-color: rgb(79 124 154);
    border: 1px solid rgb(18, 72, 108) !important;
}


.show-input-comment {
    display: block !important;
}

.first {
    border: 0;
    height: 4px;
    border-radius: 10px;
    background: rgb(186, 61, 61);
    background: linear-gradient(90deg, rgba(111,159,190,1) 0%, rgba(111,159,190,1) 20%, rgba(255, 255, 255, 0) 22%);}

.second {
    border: 0;
    height: 4px;
    border-radius: 10px;
    background: rgb(186, 61, 61);
    background: linear-gradient(90deg, rgba(111,159,190,1) 0%, rgba(111,159,190,1) 40%, rgba(255, 255, 255, 0) 42%);
}

.third {
    border: 0;
    height: 4px;
    border-radius: 10px;
    background: rgb(186, 61, 61);
    background: linear-gradient(90deg, rgba(111,159,190,1) 0%, rgba(111,159,190,1) 60%, rgba(255, 255, 255, 0) 62%);
}

.fourth {
    border: 0;
    height: 4px;
    border-radius: 10px;
    background: rgb(186, 61, 61);
    background: linear-gradient(90deg, rgba(111,159,190,1) 0%, rgba(111,159,190,1) 80%, rgba(255, 255, 255, 0) 82%);
}

.fifth {
    border: 0;
    height: 4px;
    border-radius: 10px;
    background: rgb(186, 61, 61);
    background: linear-gradient(90deg, rgba(111,159,190,1) 0%, rgba(111,159,190,1) 100%, rgba(255, 255, 255, 0) 100%);
}