.first-step {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}
.img-ques{
    display: block;
    height: 20%;
    width: 85%;
}
.span-ques{
    height: 20%;
    display: flex;
    text-align: center;
    width: 90%;
    line-height: 34px;
    font-size: 18px;
    justify-content: center;
}
.h4-ques {
    font-size: 20px;
}
.butt-ques {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    width: 70%;
}
.begin-surveytn {
    height: 50%;
    width: 70%;
    background-color: #131428;
    color: white;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 8px;
}
.begin-surveytn:active {
    background-color: #0a0a14;
    color: white;
    border: 1px solid rgb(255, 255, 255) ;
}
.begin-surveytn:hover {
    background-color: #040523;
    color: white;
    border: 1px solid rgb(255, 255, 255) ;
}
.begin-surveytn:focus {
    background-color: #06060b;
    color: white;
    border: 1px solid rgb(255, 255, 255) ;
}